import React from "react";
import GitHub from "../../Assets/GitHub-Icon.png";

const ProjectMobileContainer = ({
  title,
  githubLink,
  image,
  type,
  duration,
  description,
  tech,
}) => {
  return (
    <div data-aos="fade-up" className="Project_Form_Style">
      <img
        data-aos="flip-right"
        className="Mobile_Image_Style"
        alt="mero"
        src={image}
      />
      <div data-aos="fade-up"  className="Project_Info_Style">
        <div>
          <div className="Project_Title_Container">
            <span className="mainTitle">{title}</span>
            <a href={githubLink} target="_blank" rel="noreferrer">
              <img className="Project_GitHubH_Icon" alt="mero" src={GitHub} />
            </a>
          </div>
          <div className="ProjectDetailStyle">
            <span className="SubmainTitle">{type}</span>
            <span className="SubmainTitle">{duration}</span>
          </div>
          <hr className="Project_custom_hr" />
        </div>
        <li className="SubmainDescription">{description}</li>
        <div className="Project_TechContainer_Style">
          {tech.map((techItem, index) => (
            <span key={index} className="Project_TechStyle_Text_Style">
              {techItem}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectMobileContainer;
