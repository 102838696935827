import React, { useState, useEffect } from "react";
import d1 from "../../Assets/Design/d1.png";
import d2 from "../../Assets/Design/d2.png";
import d3 from "../../Assets/Design/d3.png";
import d4 from "../../Assets/Design/d4.png";
import d5 from "../../Assets/Design/d5.png";
import "../../Styles/Figma.css";
import "aos/dist/aos.css";
import Aos from "aos";
import "animate.css";
//---------------------------------- Image Container sections ---------------------
const images = [d1, d2, d3, d4, d5];

const FigmaDesign = () => {
  //---------------------------------- Loop image sections ---------------------
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const currentComputerImage = images[currentImageIndex];
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease-in-quart",
    });
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      // Change the image index to the next one in the array
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 5 seconds

    return () => {
      clearInterval(interval); // Clean up the interval on unmount
    };
  }, []);
  //---------------------------------- const sections ---------------------
  const DesginCopy = (
    <h1 data-aos="zoom-in-down" className="TitleStyle">
      #Design
    </h1>
  );
  //---------------------------------- iMac card 1 ---------------------
  const iMacCard = (
    <div className="AppContainerStyle">
      <img
        data-aos="flip-up"
        className="ImageScrollingStyle"
        src={currentComputerImage}
        alt={`${currentImageIndex + 1}`}
      />
    </div>
  );
  //---------------------------------- Container sections ---------------------
  const Container = () => {
    return (
      <div id="Designs-section" className="FigmaContainerStyle">
        {DesginCopy}
        {iMacCard}
      </div>
    );
  };
  return Container();
};

export default FigmaDesign;
