import React from "react";
import "../../Styles/Cert.css";
import "aos/dist/aos.css";
import "animate.css";

const CertForm = ({ link, CertImage, title, data, info, isReversed }) => {
  return (
    // <div className="VerticalViewCert">
    <div
      className={`VerticalViewCert ${
        isReversed ? "VerticalViewCertReversed" : ""
      }`}
    >
      <a
        data-aos="zoom-out-up"
        href={link}
        className="LinkContainer"
        target="_blank"
        rel="noreferrer"
      >
        <img className="CertView" alt="mero" src={CertImage} />
      </a>
      <div data-aos="zoom-in-up" className="CertCard">
       <div className="TitleContainerCert">
       <span className="mainTitle">{title} </span>
        <span className="SubmainTitle">{data}</span>
       </div>
        <hr className="custom-Exp-hr" />
        <li className="SubmainDescription">{info}</li>
        {/* <button
            className="Check_website_button"
            // onClick={() => window.open(websiteLink, "_blank")}
          >
            Check the Cert.
          </button> */}
      </div>
    </div>
  );
};

export default CertForm;
