import React from "react";
import GitHub from "../../Assets/GitHub-Icon.png";
import VisitWebsite from "../../Assets/play.png";
import "../../Styles/project.css";
import "aos/dist/aos.css";

const ProjectCard = ({
  title,
  githubLink,
  image,
  type,
  duration,
  tech,
  websiteLink,
  description,
  deployed
}) => {
  return (
    <div data-aos="fade-up" className="Project_Form_Style">
      <img
        data-aos="flip-right"
        className="Website_Image_Style"
        alt="mero"
        src={image}
      />
      <div data-aos="fade-up"  className="Project_Info_Style">
        <div>
          <div className="Project_Title_Container">
            <span className="mainTitle">{title}</span>
            <a href={githubLink} target="_blank" rel="noreferrer">
              <img className="Project_GitHubH_Icon" alt="mero" src={GitHub} />
            </a>
          </div>
          <div className="ProjectDetailStyle">
            <span className="SubmainTitle">{type}</span>
            <span className="SubmainTitle">{duration}</span>
          </div>
          <hr className="Project_custom_hr" />
        </div>
        <li className="SubmainDescription">{description}</li>
        <div className="Project_TechContainer_Style">
          {tech.map((techItem, index) => (
            <div className="Project_TechStyle_Text_Style" key={index}>
              {techItem}
            </div>
          ))}
        </div>
        <div className="Button_Container">
          {deployed &&<button
            className="Check_website_button"
            onClick={() => window.open(websiteLink, "_blank")}
          >
            <img
              alt="Visit Website"
              src={VisitWebsite}
              style={{ marginRight: "10px" }}
            />
            Visit Website
          </button>}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
