// ExperienceData.js
import WR from "../../Assets/WR.jpg";
import NL from "../../Assets/NLE.jpg";
import MU from "../../Assets/MUN.jpg";
import NA from "../../Assets/NA.jpg";
import Yekola from "../../Assets/Yekola.png";

const ExperienceData = [
  {
    title: "Yekola",
    subTitle: "Tech lead Engineer – Contract, Remote",
    date: "Sept. 2023 - Present",
    logoSrc: Yekola,
    techSkills: [
      "React Native", 
      "Redux ToolKit",
      "AWS Services",
      "Mongo DB",
      "Jira",
      "LLM"
    ],
    descriptions: [
      "Directed comprehensive assessments of technology options and architecture designs, leading to enhanced system scalability; recommendations were implemented, resulting in a 10% increase in transactions without performance degradation.",
      "Developed communication plans to ensure transparency across teams, promoting open collaboration and streamlined project updates, thereby minimizing misunderstandings and maximizing project efficiency.",
      "Orchestrated feature development initiatives alongside product managers, prioritizing customer feedback to streamline project workflows; this approach led to a 20% reduction in development time.",
    ],
  },
  {
    title: "Wind River",
    subTitle: "DevOps Engineer - Remote - Ottawa, ON, Canada",
    date: "Sept. - Dec. 2021",
    logoSrc: WR,
    techSkills: [
      "Apache Storm", 
      "kubernetes", 
      "Data Simulation and Filtering", 
      "Documentation and Communication"
    ],
    descriptions: [
      "Decomposed Kubernetes into microservices that support independent deployment, resulting in enhanced operational flexibility; the solution has been adopted by 5+ teams, facilitating faster feature rollouts and improved user experience.",
      "Authored and implemented an Apache Storm guide, simulating real-time data filtering and processing, thereby providing a comprehensive resource for data engineers and streamlining data processing workflows.",
      "Conducted analysis of K8S deployment to identify areas for improvement, resulting in a 5% reduction in onboarding time, ultimately enhancing team productivity. ",
    ],
  },
  {
    title: "Wind River",
    subTitle: "Testing Developer - Remote - Ottawa, ON, Canada",
    date: "Jan. - April. 2021",
    logoSrc: WR,
    techSkills: [
      "Technical Analysis",
      "Requirements Analysis",
      "Alignment with Specifications ",
      "High Level Requirement for ARM",
    ],
    descriptions: [
      "Analyzed code and crafted high-level test cases aligned with API Specifications to ensure seamless system integration, resulting in a robust and reliable technical architecture that meets business requirements.",
    ],
  },
  {
    title: "NLEats",
    subTitle:
      "Full-Stack Developement Team Lead - Remote - St. Johns, NL, Canada",
    date: "May. - Dec. 2020",
    logoSrc: NL,
    techSkills: [
      "Team Leadership", 
      "Architectural Design", 
      "Vision Control",
      "Agile Methodologies",
    ],
    descriptions: [
      "Established a collaborative culture by facilitating cross-functional workshops for 8 teams; these sessions generated innovative solutions and increased project completion rates by 15%, fostering a more engaged workforce.",
      "Formed and guided a cross-functional team to address project hurdles, introducing innovative solutions that empowered 12+ team members to exceed objectives, leading to enhanced morale and a cohesive work environment.",
      "Leveraged Agile methodologies to deliver the MCP, adapting to changing requirements and ensuring seamless collaboration among team members, resulting in successful project completion. "     
    ],
  },
  {
    title: "Wind River",
    subTitle: "Software Engineered - Ottawa, ON, Canada",
    date: "Sept. - Dec. 2019",
    logoSrc: WR,
    techSkills: [
      "Docker", 
      "Jira", 
      "Coverity", 
      "Ansible", 
      "Jenkins", 
      "CodeXM",
      "Teamwork"
    ],
    descriptions: [
      "Automated the setup process for WASP, Helix, and Simics using Docker volumes, reducing the time required from 5-8 hours to 30 minutes, freeing up valuable developer time for other tasks.",
      "Utilized CodeXM to optimize code quality and performance, integrating Coverity checkers to improve application readiness for deployment, resulting in tangible performance improvements.",
    ],
  },
  {
    title: "Memorial University of Newfoundland - Chemistry department",
    subTitle: "Full-Stack Developer - St. John's, NL, Canada",
    date: "May - Sept. 2019",
    logoSrc: MU,
    techSkills: [
      "React JS", 
      "Node JS", 
      "Docker", 
      "Express", 
      "MongoDB"
    ],
    descriptions: [
      "Develop front-end of the Chemistry dep “Retrievium” using ReactJS, CSS, Query, and Bootstrap",
    ],
  },
  {
    title: "North Atlantic Petroleum",
    subTitle: "Electrical Engineer - Come By Chance, NL, Canada",
    date: "Jan. - April 2019",
    logoSrc: NA,
    techSkills: [],
    descriptions: [
      "Recorded thoroughly electrical circuit and instrumentation designs for the capital projects department ensuring meticulous and precise documentation.",
      "Fostered collaborative relationships with colleagues involved in the maintenance of electrical units powering the refinery, actively  contributing to their efficient operation and upkeep.",
    ],
  },
  // Add more company experiences here...
];

export default ExperienceData;
