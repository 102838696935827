import weatherMobile from "../../Assets/Mobile/W-Mobile-Small.png";
import hnMobile from "../../Assets/Mobile/HN-Mobile-Small.png";
import LLMobile from "../../Assets/Mobile/LL-Mobile-Small.png";
import NLMac from "../../Assets/Mobile/NL-Laptop.png";
import MFMac from "../../Assets/Mobile/MF-Mac.png";
import SummaryExtension from "../../Assets/Design/2.png";
import Assemblia from "../../Assets/Design/3.png";
import MYFlexMobile from "../../Assets/Mobile/MYFlex-Mobile.png";

const ProjectInfo = [
  {
    Project_type: "Mobile",
    title: `MyFlex Mobile"`,
    type: "Solo Project",
    duration: "Jan. 2024 - Present",
    tech: ["React Navtive", "Redux ToolKit", "Nodejs", "MongoDB", "Figma"],
    image: MYFlexMobile,
    description:
      "Crafted mobile app with personalized features like favorites to elevate the movie-watching experience.",
    githubLink: "https://github.com/arsmohamed/myflex-mobile",
    // deployed: true,
    // websiteLink:
    //   "https://chromewebstore.google.com/u/1/detail/summary-extension/pgonfojaigpaeaeaiajjkpnjnnacpnad?hl=en",
  },
  {
    Project_type: "Web",
    title: `Assemblia "Developer Tool"`,
    type: "Group Project",
    duration: "Dec. 2023 - Jan. 2024",
    tech: ["React Js", "Nodejs", "Express"],
    image: Assemblia,
    description:
      "Drag, drop, style components visually, generate code in real-time. Create, preview, export effortlessly.",
    // githubLink: "https://github.com/arsmohamed/summary-Extension.git",
    // deployed: true,
    // websiteLink:
    //   "https://chromewebstore.google.com/u/1/detail/summary-extension/pgonfojaigpaeaeaiajjkpnjnnacpnad?hl=en",
  },
  {
    Project_type: "Web",
    title: `Summarizer "User Extension"`,
    type: "Personal Project",
    duration: "Nov. 2023",
    tech: ["Chat-GPT API", "React Js", "AWS Translate" , "AWS Lambda", "AWS API Gateway"],
    image: SummaryExtension,
    description:
      "Developed a browser extension leveraging API integration to offer users an efficient text summarization and translation tool. The extension allows users to input paragraphs or text content, which are then summarized using an API-powered algorithm. Additionally, the tool integrates language translation functionality, enabling the translated summary of the original content into various languages.",
    githubLink: "https://github.com/arsmohamed/summary-Extension.git",
    deployed: true,
    websiteLink:
      "https://chromewebstore.google.com/u/1/detail/summary-extension/pgonfojaigpaeaeaiajjkpnjnnacpnad?hl=en",
  },
  {
    Project_type: "Mobile",
    title: `LittleLemon "TableFinder App"`,
    type: "Personal Project",
    duration: "Jan. 2023",
    tech: ["Restaurants API", "React Native"],
    image: LLMobile,
    description:
      " spearheaded a Course Evaluation Project, employing React Native to craft a mobile application aimed at identifying available tables in various cities and seamlessly incorporating them into a comprehensive list.",
    githubLink: "https://github.com/arsmohamed/little-lemon-react-native",
  },
  {
    Project_type: "Web",
    title: `MyFlex "Movies Tacking Website"`,
    type: "Group Project",
    duration: "May - Sept. 2021",
    tech: ["React JS", "Node JS", "MongoDB", "Express", "Deployed on AWS EC2"],
    image: MFMac,
    description:
      "Engineered a comprehensive movie tracking application featuring user authentication, search functionality, and personalized movie management. Users can log in, search for movies, mark favorites, track watched status, and receive personalized movie recommendations based on genre preferences.",
    githubLink:
      "https://github.com/MyFelx/App",
    websiteLink:
      "http://ec2-3-141-165-79.us-east-2.compute.amazonaws.com:3333/",
      deployed: true,
  },
  {
   Project_type: "Mobile",
    title: `Clim "Weather App"`,
    type: "Personal Project",
    duration: "May - Sept. 2020",
    tech: ["Weather API", "Figma", "Swift 5 (Storyboard)", "MVC architectural"],
    image: weatherMobile,
    description:
      "Developed a feature-rich weather application, emulating the functionality of the Apple Weather app. The app utilizes live data from APIs to present detailed daily and weekly weather forecasts, offering hourly breakdowns and location-based weather searches using longitude and latitude coordinates.",
    githubLink: "https://github.com/My-Swift-Apps/Clima-New-version",
  },
  {
    Project_type: "Web",
    title: `Notask "Versatile Organization Solution"`,
    type: "Personal Project",
    duration: "Sept. - Dec. 2020",
    tech: [
      "React JS",
      "Node JS",
      "MongoDB",
      "Express",
      "Deployed on Github Pages",
    ],
    image: NLMac,
    description:
      "Developed a comprehensive application integrating note-taking functionalities with a robust calendar system. The app enables users to create, update, and manage events, including recurring events, within an intuitive calendar interface while also providing a seamless note-taking feature.",
    githubLink: "https://github.com/My-keeper/notesk",
    websiteLink: "https://my-keeper.github.io/notesk/#/calendar",
    deployed: true,
  },
  {
    Project_type: "Mobile",
    title: `HackerNews "Tech News Terminal"`,
    type: "Personal Project",
    duration: "May 2020",
    tech: ["Swift 5 (Storyboard)", "Teach news API", "MVC architectural"],
    image: hnMobile,
    description:
      "Developed a News Terminal that utilizes the Algolia API to deliver precise and up-to-date technology-related news and information. ",
    githubLink: "https://github.com/My-Swift-Apps/Hacker-News",
  },
]; 

export default ProjectInfo ;
