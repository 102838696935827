import React from "react";
import Container from "./Components/Container";
import "@fontsource/dancing-script";
import "animate.css";
import "./App.css";

function App() {

  return (
    <div className="overlay">
      <Container /> 
    </div> 
  );
}

export default App;