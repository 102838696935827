import React from "react";
import "../../Styles/Cert.css";
import "aos/dist/aos.css";
import "animate.css";

const ExperienceForm = ({
  title,
  subTitle,
  date,
  logoSrc,
  techSkills,
  descriptions,
  index,
}) => {
  return (
    <div
      key={index}
      className={
        index % 2 === 0 ? "CompanyContainer" : "CompanyContainerReversed"
      }
    >
      <div
        data-aos={"fade-up"}
        // data-aos={index % 2 === 0 ? "fade-left" : "fade-right"}
        className="CompanyLogoContainer"
      >
        <img className="CompanyLogo" alt="mero" src={logoSrc} />
      </div>
      <div
        data-aos={"fade-up"}
        // data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
        className="DescriptionContainer"
      >
        <span className="mainTitle">{title}</span>
        <span className="SubmainTitle">{subTitle}</span>
        <span className="SubmainTitle">{date}</span>
        <hr className="custom-Exp-hr" />
        {descriptions.map((description, index) => (
          <li className="SubmainDescription" key={index}>
            {description}
          </li>
        ))}
        <div className="TechContainer">
          {techSkills.map((techSkill, index) => (
            <span className="TechStyle" key={index}>
              {techSkill}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExperienceForm;
