import React from "react";
import Experience from "./Container/Experience";
import "../Styles/Container.css";
import Projects from "./Container/Project";
import Contact from "./Container/Contact";
import Navbar from "./Container/Navbar";
import Cert from "./Container/Cert";
import Figma from "./Container/Figma";
import "../Styles/Text.css";
import NewHero from "./Container/NewHero";

function Container() {
  const Message = (
    <p className="messageRights">© 2023 Amr R. Mohamed. All rights reserved.</p>
  );
  const ContainerView = (
    <>
      <Navbar />
      <div className="ContainerView">
        <NewHero />
        <Experience />
        <Projects />
        <Cert />
        <Figma />
        <Contact />
        {Message}
      </div>
    </>
  );
  return ContainerView;
}

export default Container;
