import React, { useRef, useEffect } from "react";
import "../../Styles/newHero.css"
import lottie from 'lottie-web';
import "animate.css";
import Linkedin from "../../Assets/linkedin.png";
import Github from "../../Assets/GitHub.png";

const Hero = () => {
  const container = useRef(null)
  const handleImgClick = (link) => {
    window.open(link, '_blank'); // Opens link in a new tab
  };
  useEffect(()=>{
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      // animationData: require('../../Assets/Hero/Laptop-Animation.json')
      animationData: require('../../Assets/Hero/Desk-Animation.json')
    })
  },[])

  const AnimatedContainer = <div className="Animated_Container_Style" ref={container}></div>

//Animation from Lottie  Small Container
  const SmallContainer = useRef(null)

  useEffect(()=>{
    lottie.loadAnimation({
      container: SmallContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../Assets/Hero/Laptop-Animation.json')
    })
  },[])

  const SmallAnimatedContainer = <div className="Animated_Small_Container_Style" ref={SmallContainer}></div>

//Social Media Section 
  const GitHubContainer = <img
    alt="mero"
    src={Github}
    className="Scoial_Icon_Style"
    onClick={() => handleImgClick('https://github.com/arsmohamed')}
    />
  const LinkedinContainer = <img
    alt="mero"
    src={Linkedin}
    className="Scoial_Icon_Style"
    onClick={() => handleImgClick('https://www.linkedin.com/in/amr-r-mohamed-933a6b156/')}
    />
  const SocialContainer = <div className="Social_container_Style"> 
    {LinkedinContainer}
    {GitHubContainer}
  </div>

// Name Section 
  const Name = <span className="Hero_Name_Style"> Amr R. Mohamed</span>
  const Occupation = <span className="Hero_Occupation_Style"> Software Developer</span>
  const InfoContainer = <div className="Info_Container_Style"> 
  {Name}
  {Occupation}
  </div>
  const Animated_Social_Container = <div className="Animated_Social_Container_Style">
    {SmallAnimatedContainer}
    {SocialContainer}
  </div>
//Const Right Child Container 
  const TopRightChildSide = <div className="Top_Right_Child_Container_Style" >{AnimatedContainer}</div>
  const BottomRightChildSide = <div className="Bottom_Right_Child_Container_Style" ></div>
  
//Const Left Child Container 
  const TopLeftChildSide = <div className="Top_Left_Child_Container_Style" >
    {InfoContainer}
    {Animated_Social_Container}
  </div>
  const BottomLeftChildSide = <div className="Bottom_Left_Child_Container_Style" >
  </div>

//Side Container 
  const RightSide = <div className="Right_Container_Style" >
    {TopRightChildSide}
    {BottomRightChildSide}
  </div>
  const LeftSide = <div className="Left_Container_Style" >
    {TopLeftChildSide}
    {BottomLeftChildSide}
  </div>

    return (
    <div id="Hero-section" className="Hero_Container_Style">
      {LeftSide}
      {RightSide}
    </div>
  );
};

export default Hero;
