import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Logo from "../../Assets/Logo.png";
import { Link } from "react-scroll";
import "../../Styles/Navbar.css";

const Navbar = () => {
  //---------------------------------- useState sections ---------------------
  const [navbarColor, setNavbarColor] = useState(false);
  const [HamburgerIconClicked, setHamburgerIconClicked] = useState(false);

  //---------------------------------- Close Humburger sections ---------------------
  const CloseHamburgerNav = () => setHamburgerIconClicked(false);

  // Check screen width and conditionally render the additional div
  const isSmallScreen = window.innerWidth < 688;

  //---------------------------------- useEffect sections ---------------------
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 20; // Check if scrolled more than 20px
      setNavbarColor(scrolled); // Update navbarColor state based on scroll position
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: navbarColor ? "rgba(0, 0, 0, 0.8)" : "transparent", // Change background color based on navbarColor state
  };

  //---------------------------------- LogoIcon sections ---------------------
  const LogoIcon = (
    <Link to="Hero-section" smooth={true} duration={500}>
      <img className="LogoIcon" alt="mero" src={Logo} />
    </Link>
  );
  //---------------------------------- NavIcontext sections ---------------------
  const Content = (
    <>
      <Link
        to="Aboutme-section"
        smooth={true}
        duration={500}
        className="TextNavStyle"
        onClick={isSmallScreen ? CloseHamburgerNav : undefined}
      >
        # Experience
      </Link>
      <Link
        to="Projects-section"
        smooth={true}
        duration={500}
        className="TextNavStyle"
        onClick={isSmallScreen ? CloseHamburgerNav : undefined}
      >
        # Projects
      </Link>
      <Link
        to="Certificates-section"
        smooth={true}
        duration={500}
        className="TextNavStyle"
        onClick={isSmallScreen ? CloseHamburgerNav : undefined}
      >
        # Certificates
      </Link>
      <Link
        to="Designs-section"
        smooth={true}
        duration={500}
        className="TextNavStyle"
        onClick={isSmallScreen ? CloseHamburgerNav : undefined}
      >
        # Designs
      </Link>
      <Link
        to="contact-section"
        smooth={true}
        duration={500}
        className="TextNavStyle"
        onClick={isSmallScreen ? CloseHamburgerNav : undefined}
      >
        # Contact me
      </Link>
    </>
  );

  // ---------------------------------- normal Navbar sections ---------------------
  const NavContainer = (
    <div className="navbar" style={navbarStyle}>
      {LogoIcon}
      <div className="NavIcontext">{Content}</div>
    </div>
  );

  // ---------------------------------- hamburger Icon Navbar sections ---------------------
  const hamburgerLogoIcon = (
    <Link to="Hero-section" smooth={true} duration={500}>
      <img className="hamburger_Logo_Icon_Syle" alt="mero" src={Logo} />
    </Link>
  );
  // ---------------------------------- hamburger Navbar sections ---------------------
  const MainhamburgerNav = (
    <div
      className="Main_hamburger_Nav_Style"
      style={{ height: HamburgerIconClicked ? "auto" : "20px" }}
    >
      {hamburgerLogoIcon}
      {HamburgerIconClicked ? (
        <CloseOutlined style={{ color: "white" }} onClick={CloseHamburgerNav} />
      ) : (
        <MenuOutlined
          style={{ color: "white" }}
          onClick={() => setHamburgerIconClicked(true)}
        />
      )}
      {/* <p>This is the additional content for smaller screens.</p> */}
    </div>
  );
  const hamburgerNavContainer = (
    <div className="hamburger_Nav_Style">
      {MainhamburgerNav}
      {HamburgerIconClicked && (
        <div className="Hamburger_Nav_Icon_text_Style">{Content}</div>
      )}
    </div>
  );
  // ---------------------------------- Return sections ---------------------
  return (
    <div className="navbarContainer">
      {isSmallScreen ? hamburgerNavContainer : NavContainer}
    </div>
  );
};

export default Navbar;
