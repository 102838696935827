import React, { useState, useEffect, useRef } from "react";
import Linkedin from "../../Assets/linkedin.png";
import Credly from "../../Assets/credly.png";
import Github from "../../Assets/GitHub.png";
import Figma from "../../Assets/figma.png";
import lefty from "../../Assets/Righy.png";
import righty from "../../Assets/Left.png";
import emailjs from "@emailjs/browser";
import b1 from "../../Assets/b1.png";
import b2 from "../../Assets/b2.png";
import b3 from "../../Assets/b3.png";
import b4 from "../../Assets/b4.png";
import "../../Styles/Contact.css";
import "aos/dist/aos.css";
import "animate.css";
import Aos from "aos";

const ContactContainer = () => {
  //---------------------------------- const sections ---------------------
  const form = useRef();
  const [isName, setIsName] = useState("");
  const [isEmail, setIsEmail] = useState("");
  const [isMessage, setIsMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // State to track window width
  //---------------------------------- clearing the message if its sent sections ---------------------
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease-in-quart",
    });

    // Add a resize event listener
    window.addEventListener("resize", handleWindowResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (isMessageSent) {
      // Automatically clear the "Message sent!" message after 3 seconds
      const timer = setTimeout(() => {
        setIsMessageSent(false);
      }, 3000);

      // Clear the timer if the component unmounts or if a new message is sent
      return () => clearTimeout(timer);
    }
  }, [isMessageSent]);
  //---------------------------------- const sections ---------------------
  const DesginCopy = (
    <h1 data-aos="zoom-in-down" className="TitleStyle">
      #Contact me
    </h1>
  );

  //Books
  const Books = (
    <>
      <img
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="300"
        className="BView1"
        alt="mero"
        src={b1}
      />
      <img
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="300"
        className="BView2"
        alt="mero"
        src={b2}
      />
      <img
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="300"
        className="BView3"
        alt="mero"
        src={b3}
      />
      <img
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="300"
        className="BView4"
        alt="mero"
        src={b4}
      />
    </>
  );
  //Social
  const Scoial = (
    <div data-aos="fade-down" className="ScoialContainer">
      <a href="https://linkedin.com/in/amr-r-mohamed-933a6b156/">
        <img className="ScoialIcon" alt="mero" src={Linkedin} />
      </a>
      <a href="https://github.com/arsmohamed">
        <img className="ScoialIcon" alt="mero" src={Github} />
      </a>
      <a href="https://figma.com/@mero1">
        <img className="ScoialIcon" alt="mero" src={Figma} />
      </a>
      <a href="https://www.credly.com/users/amr-mohamed.01437fd6">
        <img className="ScoialIcon" alt="mero" src={Credly} />
      </a>
    </div>
  );
  // Function to handle input changes and update state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "user_name") {
      setIsName(value);
    } else if (name === "user_email") {
      setIsEmail(value);
    } else if (name === "message") {
      setIsMessage(value);
    }
  };
  // Function to handle form submission
  const sendEmail = (e) => {
    e.preventDefault();
    // Check if any of the fields are empty
    if (!isName || !isEmail || !isMessage) {
      setErrorMessage("Please fill in all fields.");
      return;
    }
    // Check if the email is valid
    if (!isValidEmail(isEmail)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    //sending the email
    e.preventDefault();

    emailjs
      .sendForm(
        "service_scckzv8",
        "template_wi68m2r",
        form.current,
        "HP8qkrR41gDY7tzSb"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    //reseting the values
    setIsName("");
    setIsEmail("");
    setIsMessage("");
    setErrorMessage("");
    setIsMessageSent(true);
  };
  // Function to validate email format
  const isValidEmail = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };
  //Input Section
  const inputContainer = (
      <form ref={form} onSubmit={sendEmail}>
        <div className="InputDevContainer">
          {Scoial}
          <span className="TextStyle"> Name</span>
          <input
            type="text"
            className="custom-input"
            name="user_name"
            value={isName}
            onChange={handleInputChange}
            placeholder="Name ..."
          />
          <span className="TextStyle"> Email</span>
          <input
            type="text"
            className="custom-input"
            name="user_email"
            value={isEmail}
            onChange={handleInputChange}
            placeholder="Email ..."
          />
          <span className="TextStyle"> Message</span>
          <textarea
            rows={10}
            name="message"
            value={isMessage}
            onChange={handleInputChange}
            className="custom-MessageInput"
            placeholder="Message ..."
          />
          {errorMessage && (
            <span className="TextErrorStyle">{errorMessage}</span>
          )}
          {isMessageSent && (
            <span className="TextsentStyle">Message sent!</span>
          )}
          <div className="buttonContainer">
            <button type="submit" className="send-button">
              Send
            </button>
          </div>
        </div>
      </form>
  );

  // Conditionally render the leftychar and rightychar elements based on window width
  const shouldShowChars = windowWidth >= 800;

  //The Card
  const ContactCard = (
    <div data-aos="zoom-in">
    
    <div className="VerticalViewContact">
      <div className="ContactCard">
        {Books}
        <div className="InfoDevContainer">
          {shouldShowChars && (
            <img
            data-aos="fade-right"
            className="LeftyCharStyle"
            alt="mero"
            src={lefty}
            />
            )}
          {inputContainer}
          {shouldShowChars && (
            <img
            data-aos="fade-left"
            className="RightyCharStyle"
            alt="mero"
            src={righty}
            />
            )}
        </div>
      </div>
    </div>
  </div>
  );
  //---------------------------------- Container sections ---------------------
  const Container = () => {
    return (
      <div id="contact-section" className="ContainerViewContact">
        {DesginCopy}
        {ContactCard}
      </div>
    );
  };

  return Container();
};

export default ContactContainer;
