import React, { useEffect } from "react";
import "../../Styles/Experience.css";
import "aos/dist/aos.css";
import Aos from "aos";
import ExperienceData from "../Info/ExperienceData";
import ExperienceForm from "../Form/ExperienceForm";
const ExperienceContainer = () => {
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease-in-quart",
    });
  }, []);

  return (
    <div id="Aboutme-section" className="ExperienceView">
      <h1 data-aos="zoom-in-down" className="TitleStyle">
        #Experience
      </h1>
      <div className="WorkContainer">
        {ExperienceData.map((experience, index) => (
          <ExperienceForm
            key={index}
            logoSrc={experience.logoSrc}
            title={experience.title}
            subTitle={experience.subTitle}
            date={experience.date}
            techSkills={experience.techSkills}
            descriptions={experience.descriptions}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default ExperienceContainer;
