import React, { useEffect } from "react";
import "../../Styles/Cert.css";
import "aos/dist/aos.css";
import "animate.css";
import Aos from "aos";
import CertForm from "../Form/CertForm";
import CertData from "../Info/CertData";

const CertContainer = () => {
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease-in-quart",
      // Conditionally enable or disable AOS based on window width
      // disable: window.innerWidth < 1250, // Adjust the threshold as needed
    });
  }, []);
  //---------------------------------- const sections ---------------------
  const CertText = (
    <h1 data-aos="zoom-in-down" className="TitleStyle">
      #Certification
    </h1>
  );
  //---------------------------------- Container sections ---------------------
  const Container = () => {
    return (
      <div id="Certificates-section" className="ContainerViewCert">
        {CertText}
        <div className="certcontainer">
          {CertData.map((Cert, index) => (
            <CertForm
              key={index}
              link={Cert.link}
              CertImage={Cert.CertImage}
              title={Cert.title}
              data={Cert.data}
              info={Cert.info}
              isReversed={index % 2 !== 0} // Apply row-reverse for odd indexes
            />
          ))}
        </div>
      </div>
    );
  };

  return Container();
};

export default CertContainer;
