import React, { useEffect } from "react";
import "../../Styles/project.css";
import "aos/dist/aos.css";
import Aos from "aos";
import ProjectMobileForm from "../Form/ProjectMobileForm";
import ProjectWebsiteForm from "../Form/ProjectWebsiteForm";
import ProjectInfo from "../Info/ProjectData";

const ProjectSection = () => {
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease-in-quart",
      // Conditionally enable or disable AOS based on window width
      // disable: window.innerWidth < 1250, // Adjust the threshold as needed
    });
  }, []);

  //---------------------------------- const sections ----------------------------
  const TitleProject = (
    <h1 data-aos="zoom-in-down" className="TitleStyle">
      #Projects
    </h1>
  ); 
//---------------------------------- Project Container sections ----------------
const ProjectContainer1 = (
  <div className="Project_Container_Style">
    {ProjectInfo.map((project, index) => {
      if (project.Project_type === "Mobile") {
        return (
          <ProjectMobileForm
            key={index}
            title={project.title}
            type={project.type}
            duration={project.duration}
            tech={project.tech}
            image={project.image}
            githubLink={project.githubLink}
            description={project.description}
            websiteLink={project.websiteLink}
            deployed={project.deployed}
          />
        );
      } else if (project.Project_type === "Web") {
        return (
          <ProjectWebsiteForm
            key={index}
            title={project.title}
            type={project.type}
            duration={project.duration}
            tech={project.tech}
            image={project.image}
            githubLink={project.githubLink}
            description={project.description}
            websiteLink={project.websiteLink}
            deployed={project.deployed}
          />
        );
      }
      return null; // You can handle other project types or omit them as needed
    })}
  </div>
);
  //---------------------------------- Project View sections ---------------------
  const ProjectView = (
    <div id="Projects-section" className="ProjectView">
      {TitleProject}
      {ProjectContainer1}
    </div>
  );

  return ProjectView;
};

export default ProjectSection;
